import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ItemListRequest } from "../models/Items/ItemListRequest";
import { SortDirection } from "../models/Enum";
import { PRODUCT_TYPE_DROPDOWN_OPTIONS } from "../pages/Products/EditItem/Constants";

interface ItemsState {
    itemListRequest: ItemListRequest;
    dashboardListRequest: ItemListRequest;
    // attachmentListRequest: AttachmentListRequest;
};

const initialItemListRequest = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: "id",
    sortDirection: SortDirection.DESC,
};

// const initialAttachmentListRequest = {
//   pageIndex: 1,
//   pageSize: 10,
//   sortBy: "prjCode",
//   sortDirection: SortDirection.DESC,
// };

const initialDashboardListRequest = {
  ...initialItemListRequest,
  pageSize: 8
};

const initialProductListRequest = {
  ...initialItemListRequest,
  developmentType: PRODUCT_TYPE_DROPDOWN_OPTIONS[0].id,
  hideReleasedToInriver: true,
  hideArchivedDevelopment: true,
};

const initialState: ItemsState = {
  itemListRequest: initialProductListRequest,
  dashboardListRequest: initialDashboardListRequest,
};

const items = createSlice({
  name: 'items',
  initialState,
  reducers: {
    updateItemListRequest(state, action: PayloadAction<ItemListRequest>) {
      state.itemListRequest = action.payload;
    },
    updateDashboardListRequest(state, action: PayloadAction<ItemListRequest>) {
      state.dashboardListRequest = action.payload;
    },
  },
});

export const { updateItemListRequest, updateDashboardListRequest,} = items.actions;

export default items.reducer;
