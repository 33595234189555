import React, { useState, useEffect, useRef } from 'react';
import BarChart from '../../components/BarChart';
import { AssignmentUsersWithCount } from "../../models/ProjectDashboardDetails";
import { Checkbox, Typography, Box, FormControl, Autocomplete, TextField } from '@mui/material';
import { HEX_COLORS } from "../Products/EditItem/Constants";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface ProjectAssignmentChartProps {
  chartData: AssignmentUsersWithCount[];
}

const AssignmentUsersChart: React.FC<ProjectAssignmentChartProps> = ({ chartData }) => {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [visibleUsers, setVisibleUsers] = useState<string[]>([]);
  const [stageDropdownOptions, setUserDropdownOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userStageStatusHtml, setUserStageStatusHtml] = useState([]);
  const [totalCountsForXAxis, setTotalCountsForXAxis] = useState<number[]>([]);
  const [maxYValue, setMaxYValue] = useState<number>(50);

  const [expandedStates, setExpandedStates] = useState({});
  // State to track whether content exceeds max-height
  const [showMoreButton, setShowMoreButton] = useState({});

  const stages = Array.from(
    new Set(
      chartData
        ?.filter(user => visibleUsers.includes(user.userName)) // Filter by visible users
        .flatMap(user => user?.stageStatusesCount?.map(item => item.stage)) // Extract stages
    )
  );

  const users = Array.from(new Set(chartData?.flatMap(user => user.userName)));

  useEffect(() => {
    // Prepare the custom data as <li> elements for each user
    const stageStatusDetailWithHtml = chartData?.map(user => {
      const statusItemsHTML = generateStageStatusHTML(user?.stageStatusesCount);

      return {
        userName: user.userName,
        statusItems: statusItemsHTML
      };
    });

    setUserStageStatusHtml(stageStatusDetailWithHtml);

    const totalCounts = chartData.map(user =>
      user?.stageStatusesCount.reduce((sum, { count }) => sum + count, 0)
    );

    setTotalCountsForXAxis(totalCounts);

    const options = chartData?.map((user, index) => ({
      id: index + 1,
      name: user.userName,
    }));
    setUserDropdownOptions(options);
    // Default selected all the options
    setSelectedUsers(options);
    setVisibleUsers(users);
  }, [chartData]);

  useEffect(() => {
    if (visibleUsers.length > 0) calculateMaxY();
  }, [visibleUsers]);

  const handleUsersDropdownSelection = (selectedUsers) => {
    // store the array with TagIds to send into the api request
    let selectedUserNames = selectedUsers.map(t => t.name);
    setVisibleUsers(selectedUserNames);
    setSelectedUsers(selectedUsers);

    const prepareChartDetailsFromUserDropdownSelection = selectedUsers?.map((selectedUser) => {
      // Find the user in chartData that matches the selectedUser's ID (index + 1)
      return chartData?.find((user, index) => selectedUser.id === (index + 1));
    });

    // Prepare the custom data as <li> elements for each user
    const stageStatusDetailWithHtml = prepareChartDetailsFromUserDropdownSelection.map((user) => {
      const statusItemsHTML = generateStageStatusHTML(user?.stageStatusesCount);

      return {
        userName: user.userName,
        statusItems: statusItemsHTML
      };
    });

    setUserStageStatusHtml(stageStatusDetailWithHtml);

    // Prepare total counts for displaying on X-axis bar stack
    const totalCounts = prepareChartDetailsFromUserDropdownSelection.map((user) =>
      user?.stageStatusesCount.reduce((sum, { count }) => sum + count, 0)
    );

    setTotalCountsForXAxis(totalCounts);

  };

  const generateStageStatusHTML = (stageStatusesCount) => {
    let userStages = new Set(); // Set to track unique stages for each user
    let statusItems = []; // Array to hold <li> elements for status items

    stageStatusesCount.forEach(({ stage, status, count }) => {
      if (count > 0) {  // Only process statuses with count greater than zero
        if (!userStages.has(stage)) {
          // Add stage only once per user (prevents repeating the same stage)
          statusItems.push(`<li style="list-style-type: none;">${stage}</li>`);
          userStages.add(stage); // Mark this stage as processed
        }
        // Add the status with its count
        statusItems.push(`<li>${status}: ${count}</li>`);
      }
    });

    return statusItems.join(''); // Return the HTML as a single string
  };

  const calculateMaxY = () => {
    const visibleData = chartData?.filter((user) => visibleUsers.includes(user.userName));

    // Find the maximum individual stageStatusesCount value across all users
    const maxStatusCount = visibleData?.reduce((max, user) => {
      // Group stageStatusesCount by stage and sum counts for the same stage
      const stageCountMap = user.stageStatusesCount.reduce((acc: Record<string, number>, { stage, count }) => {
        acc[stage] = (acc[stage] || 0) + count; // Sum counts for the same stage
        return acc;
      }, {});
    
      // Find the maximum count for this user's stages
      const userMaxStatus = Math.max(...(Object.values(stageCountMap) as number[]), 0);
    
      // Compare with the global max
      return Math.max(max, userMaxStatus);
    }, 0) || 0;
    
    // Round up to the nearest 1000 or a significant step for better readability
    const roundedMax = Math.ceil(maxStatusCount / 50) * 50;

    // Ensure the max is at least 50 for proper scaling
    const getMaxValue = Math.max(roundedMax, 50);
    setMaxYValue(getMaxValue);
  };

  // Toggle the content for a specific user
  const toggleContent = (userName) => {
    setExpandedStates(prevState => ({
      ...prevState,
      [userName]: !prevState[userName],  // Toggle the state of the specific user
    }));
  };

  const chartDetailProps = {
    labels: visibleUsers,
    datasets: stages.map((stage, stageIndex) => ({
      label: stage,
      data: visibleUsers.map((user) => {
        const userStageCounts = chartData.find(item => item.userName === user);
        const stageData = userStageCounts?.stageStatusesCount?.filter(item => item.stage === stage);

        // If stageData exists, sum the count of each status within that stage
        return stageData?.reduce((sum, { count }) => sum + count, 0) || 0;
      }),
      backgroundColor: HEX_COLORS[stageIndex % HEX_COLORS.length], // Color for each stage
      barThickness: 18,
      maxBarThickness: 18,
      borderWidth: 2
    })),
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
        /* onClick: (e, legendItem, legend) => {
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex;

          // Access the dataset metadata
          const meta = chart.getDatasetMeta(datasetIndex);
          const yAxisLabel = `${legendItem.text}`;

          // Determine if the dataset is being hidden or shown
          const isHidden = !meta.hidden;

          handleLegendChange(yAxisLabel, isHidden);

          // Update the chart to reflect the changes
          meta.hidden = isHidden;
          chart.update();
        }, */
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltipItem) => {
            const { dataset, raw } = tooltipItem;
            return `${dataset.label}: ${raw}`;
          },
        },
      },
      // Add your custom data or properties for the plugin here
      customHTML: {
        // chartType: 'assignment', // Unique type for assignment chart
        stageStatusDetailWithHtml: userStageStatusHtml,
        customTotalCountForXAxis: totalCountsForXAxis
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          display: false,
        },
      },
      y: {
        stacked: false,
        min: 0,
        max: maxYValue, // Dynamically calculated max value
        ticks: {
          stepSize: maxYValue > 5 ? maxYValue / 5 : 1, // Ensure stepSize is meaningful
        },
        barPercentage: 0.1, // Adjust this value between 0 and 1 (default is 0.9)
      },
    },
  };

  const usersDropdownStyle = {
    flex: "0 0 calc(20% - 10px)",
    margin: "8px 5px",
    width: "550px",
    "& .MuiOutlinedInput-root": {
      padding: 0,
      input: {
        paddingBottom: 0,
      },
    },
  };

  // Ref to track content height for each user
  const contentRefs = useRef({});

  // Check content height after rendering
  useEffect(() => {
    userStageStatusHtml?.forEach(user => {
      const contentElement = contentRefs.current[user.userName];
      if (contentElement && contentElement.scrollHeight > 140) {
        setShowMoreButton(prevState => ({
          ...prevState,
          [user.userName]: true,  // Show the "Show More" button if content exceeds max-height
        }));
      }
    });
  }, [userStageStatusHtml]);


  return (
    <>
      <Box display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'} alignItems={'center'} minHeight={"100px"}>
        <FormControl style={{ margin: "5px 5px", width: "50%" }}>
          <Autocomplete
            sx={usersDropdownStyle}
            value={
              selectedUsers
            }
            onChange={(event, newValue) => handleUsersDropdownSelection(newValue)}
            options={stageDropdownOptions}
            getOptionLabel={(option) => option?.name || option
            }
            multiple={true}
            limitTags={3}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    selected
                  }
                />
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                key={params.id}
                {...params}
                label={'Select Users'}
                variant="outlined"
              />
            )}
          />
        </FormControl>
        <Typography fontSize={"20px"} textAlign="left" style={{ width: '50%' }}>
          Project Assignment Overview
        </Typography>
      </Box>
      <BarChart data={chartDetailProps} options={chartOptions} />
      <div className="graph-label-container">
        {userStageStatusHtml?.length > 0 &&
          userStageStatusHtml?.map((user, index) =>
            visibleUsers.includes(user.userName) && (
              <div>
                <div className="label-box">
                  <strong>{user.userName}</strong>
                  <div ref={(el) => contentRefs.current[user.userName] = el}
                    className={`content ${expandedStates[user.userName] ? 'expanded' : ''}`}>
                    <ul
                      dangerouslySetInnerHTML={{ __html: `${userStageStatusHtml[index]?.statusItems}` }}>
                    </ul>
                  </div>
                  {/* Show More / Show Less Toggle */}
                  {showMoreButton[user.userName] && (
                    <label
                      onClick={() => toggleContent(user.userName)}  // Toggle only the clicked user
                      className="toggle-label"
                    >
                      {expandedStates[user.userName] ? 'Show Less' : 'Show More'}
                    </label>
                  )}
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default AssignmentUsersChart;