import React, { useState, useEffect, useRef } from 'react';
import BarChart from '../../components/BarChart';
import { StageStatusesWithCount } from "../../models/ProjectDashboardDetails";
import { Checkbox, Typography, Box, FormControl, Autocomplete, TextField } from '@mui/material';
import { HEX_COLORS } from "../Products/EditItem/Constants";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface StageStatusesChartProps {
  chartData: StageStatusesWithCount[];
  chartTitle?: string;
}

const StageStatusesChart: React.FC<StageStatusesChartProps> = ({ chartData, chartTitle="Stage and Status Wise Active Projects" }) => {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [visibleStages, setVisibleStages] = useState<string[]>([]);
  const [stageDropdownOptions, setStageDropdownOptions] = useState([]);
  const [selectedStages, setSelectedStages] = useState([]);
  const [stageStatusHtml, setStageStatusHtml] = useState([]);

  const [expandedStates, setExpandedStates] = useState({});
  // State to track whether content exceeds max-height
  const [showMoreButton, setShowMoreButton] = useState({});

  const stages = Array.from(new Set(chartData?.map(item => item.stage)));

  const statuses = Array.from(
    new Set(
      chartData
        ?.filter((item) => visibleStages.includes(item.stage)) // Filter by visible stages
        .map((item) => item.status) // Extract statuses from the filtered data
    )
  );

  useEffect(() => {
    // Generate HTML structure with grouped stages
    const stageStatusDetailWithHtml = generateStageStatusHTMLByStage();

    // Output the result
    setStageStatusHtml(stageStatusDetailWithHtml);

    const options = stages.map((stage, index) => ({
      id: index + 1, // Generate a unique ID (can be replaced with actual IDs if available)
      name: stage,
    }));
    setStageDropdownOptions(options);
    setSelectedStages(options);
    setVisibleStages(stages); // Default to all stages being visible
  }, [chartData]);

  // Function to generate HTML for stage statuses and return the required object structure
  const generateStageStatusHTMLByStage = () => {
    // Create a map to store status items for each stage
    const stageMap = new Map();

    // Iterate through each stage status item
    chartData.forEach(({ stage, status, count }) => {
      // Generate the <li> item only if count > 0
      const statusItem = count > 0 ? `<li>${status}: ${count}</li>` : '';

      // Check if the stage already exists in the map
      if (stageMap.has(stage)) {
        // Append the new status item to the existing list for this stage
        stageMap.get(stage).push(statusItem);
      } else {
        // Create a new entry for the stage
        stageMap.set(stage, [statusItem]);
      }
    });

    // Convert the map into an array of objects with the desired structure
    return Array.from(stageMap, ([stageName, statusItems]) => ({
      stageName,
      statusItems: statusItems.join(''), // Join the status items as a string
    }));
  };

  const handleStagesDropdownSelection = (selectedStages) => {
    // store the array with TagIds to send into the api request
    let selectedStageValues = selectedStages.map(t => t.name);
    setVisibleStages(selectedStageValues);
    setSelectedStages(selectedStages);
  };


  const calculateMaxY = () => {
    const visibleData = chartData?.filter((item) => visibleStages.includes(item.stage));
    
    const maxStatus = visibleData.map(({ count }) => count);

    const maxTotalCount = Math.max(...maxStatus, 0);

    // Round up to the nearest 1000 or a significant step for better readability
    const roundedMax = Math.ceil(maxTotalCount / 50) * 50;

    // Ensure the max is at least 50 for proper scaling
    return Math.max(roundedMax, 50);
  };

  const maxYAxisValue = calculateMaxY();

  // Filter out stages with all zero values across statuses
  const filteredStages = stages.filter((stage) =>
    visibleStages.includes(stage) &&
    statuses.some((status) => {
      const stageData = chartData.find((item) => item.stage === stage && item.status === status);
      return stageData && stageData.count > 0; // Keep stage only if any status has a non-zero value
    })
  );

  // Toggle the content for a specific user
  const toggleContent = (stageName) => {
    setExpandedStates(prevState => ({
      ...prevState,
      [stageName]: !prevState[stageName],  // Toggle the state of the specific user
    }));
  };

  // Prepare the chart data
  const chartDetailProps = {
    labels: filteredStages,
    datasets: statuses.map((status, index) => ({
      label: status,
      data: filteredStages.map((stage) => {
        const stageData = chartData.find((item) => item.stage === stage && item.status === status);
        return stageData ? stageData.count : 0;
      }),
      backgroundColor: HEX_COLORS[index % HEX_COLORS.length],
      barThickness: 16,
      maxBarThickness: 16,
      borderWidth: 2,
    })),
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        display: true,
        position: 'top' as const,
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: (tooltipItem) => {
            const { dataset, raw } = tooltipItem;
            return `${dataset.label}: ${raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          display: false, // Hide the x-axis labels
        },
      },
      y: {
        stacked: false,
        min: 0,
        max: maxYAxisValue, // Dynamically calculated max value
        ticks: {
          stepSize: maxYAxisValue > 5 ? maxYAxisValue / 5 : 1, // Ensure stepSize is meaningful
        },
      },
    },
  };

  const stagesDropdownStyle = {
    flex: "0 0 calc(20% - 10px)",
    margin: "8px 5px",
    width: "520px",
    "& .MuiOutlinedInput-root": {
      padding: 0,
      input: {
        paddingBottom: 0,
      },
    },
  };

  // Ref to track content height for each user
  const contentRefs = useRef({});

  // Check content height after rendering
  useEffect(() => {
    stageStatusHtml?.forEach(stage => {
      const contentElement = contentRefs.current[stage.stageName];
      if (contentElement && contentElement.scrollHeight > 140) {
        setShowMoreButton(prevState => ({
          ...prevState,
          [stage.stageName]: true,  // Show the "Show More" button if content exceeds max-height
        }));
      }
    });
  }, [stageStatusHtml]);

  return (
    <>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} minHeight={"100px"}>
        <FormControl style={{ margin: "5px 5px", width: "50%" }}>
          <Autocomplete
            sx={stagesDropdownStyle}
            value={
              selectedStages
            }
            onChange={(event, newValue) => handleStagesDropdownSelection(newValue)}
            options={stageDropdownOptions}
            getOptionLabel={(option) => option?.name || option
            }
            multiple={true}
            limitTags={3}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    selected
                  }
                />
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                key={params.id}
                {...params}
                label={'Select Stages'}
                variant="outlined"
              />
            )}
          />
        </FormControl>
        <Typography fontSize={"20px"} textAlign="left" style={{ width: '50%' }}>
          {chartTitle}
        </Typography>
      </Box>
      <BarChart data={chartDetailProps} options={chartOptions} />
      <div className="graph-label-container">
        {stageStatusHtml?.length > 0 &&
          stageStatusHtml?.map((stage, index) =>
            visibleStages.includes(stage.stageName) && (
              <div key={index}>
                <div className="label-box">
                  <strong>{stage.stageName}</strong>
                  <div ref={(el) => contentRefs.current[stage.stageName] = el}
                    className={`content ${expandedStates[stage.stageName] ? 'expanded' : ''}`}>
                    <ul
                      dangerouslySetInnerHTML={{ __html: `${stageStatusHtml[index]?.statusItems}` }}>
                    </ul>
                  </div>
                  {showMoreButton[stage.stageName] && (
                    <label
                      onClick={() => toggleContent(stage.stageName)}  // Toggle only the clicked user
                      className="toggle-label"
                    >
                      {expandedStates[stage.stageName] ? 'Show Less' : 'Show More'}
                    </label>
                  )}
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default StageStatusesChart;
