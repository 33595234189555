import React, { useEffect, useState } from "react";
import { ItemBasicDetail } from "../../models/Items/ItemBasicDetail";
import { Box, Divider, FormControlLabel, Grid, Paper, Switch, Typography } from "@mui/material";
import { DataGrid, GridFooterContainer } from "@mui/x-data-grid";
import NotificationContent from "../../components/NotificationContent";
import { useNavigate } from "react-router-dom";
import { getSessionInfo } from "../../utils/helpers";
import { NotificationListRequest } from "../../models/NotificationListRequest";
import { Notification } from "../../models/Notification";
import { ProjectDashboardDetails, StageStatusesWithCount } from "../../models/ProjectDashboardDetails";
import api from "../../services/ApiService";
import { ApiResponse } from "../../models/ApiResponse";
import StageStatusesChart from "../ProjectDashboard/StageStatusesChart";

const UserDashboard: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [projectList, setProjectList] = useState<ItemBasicDetail[]>([]);
  const [stageStatusesWithCount, setStageStatusesWithCount] = useState<StageStatusesWithCount[]>([]);
  const [isPrjCodeWiseChecked, setIsPrjCodeWiseChecked] = useState<boolean>(true);

  let navigate = useNavigate();

  const sessionInfo = getSessionInfo();
  const userId = sessionInfo?.user?.id || null;

  const initialNotificationListRequest = {
    pageIndex: 1,
    pageSize: 10,
    userId: userId
  };

  const initialProjectDashboardRequest = {
    isPrjCodeWise: isPrjCodeWiseChecked,
    isUserWise: true
  };

  const [notificationListRequest, setNotificationListRequest] = useState<NotificationListRequest>(
    initialNotificationListRequest
  );

  const [userAssignmentChartRequest, setUserAssignmentChartRequest] = useState(initialProjectDashboardRequest);

  const ProjectListGridColumns = [
    { field: "stockcode", headerName: "Stockcode", width: 200 },
    { field: "baseCode", headerName: "Basecode", width: 180 },
    { field: "shortDesc", headerName: "Short Description", width: 250 },
    { field: "stageDisplayText", headerName: "Stage", width: 150 },
    { field: "statusDisplayText", headerName: "Status", width: 150 },
  ];

  useEffect(() => {
    fetchNotificationList();

    // Retrieve the list of visited products from localStorage
    const visitedProducts = JSON.parse(localStorage.getItem('visitedProducts')) || [];

    // Set the products state with the list from localStorage
    setProjectList(visitedProducts);
  }, []);

  /**
   * This function is used to fetch notification list from api and store in the state
   */
  const fetchNotificationList = async () => {
    setLoading(true);
    try {
      fetch(`${process.env.REACT_APP_API_URL}/Notification/list`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + sessionInfo?.token,
        },
        body: JSON.stringify(notificationListRequest)
      }).then((response) => {
        if (response.ok) {
          return response.json();
        }
      }).then((responseBodyContent) => {
        // Check if notification list is empty, then prevent to scroll the notification popup list
        if (responseBodyContent?.data?.notifications.length === 0) {
          setHasMore(false);
        }
        else {
          setNotifications((prevNotifications) => [...prevNotifications, ...responseBodyContent?.data?.notifications || []]);
          setNotificationListRequest({
            ...notificationListRequest,
            pageIndex: notificationListRequest.pageIndex + 1
          })
        }
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        console.error("Exception from notification list", error);
      });
    } catch (error) {
      console.error('Error fetching notification list:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserAssignmentChartDetail();
  }, [userAssignmentChartRequest])

  const fetchUserAssignmentChartDetail = async () => {
    try {
      api
        .post<ApiResponse<ProjectDashboardDetails>>("/dashboard/project-overview", userAssignmentChartRequest)
        .then((response) => {

          if (response.isSuccess) {
            if (response?.data?.assignmentUsersCount?.length) {

              const assignmentWithStageStatusDetail = response?.data?.assignmentUsersCount[0]?.stageStatusesCount || [];
              setStageStatusesWithCount(assignmentWithStageStatusDetail);
            }
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          console.error("Exception from User Dashboard Detail API", error);
        });

    } catch (error: any) {
      console.error("Exception from User Dashboard Detail API", error);
    }
  };

  const renderFooter = () => (
    <GridFooterContainer style={{ justifyContent: 'flex-end', padding: '0 16px' }}>
      <div>Total Records: {projectList.length}</div>
    </GridFooterContainer>
  );

  /**
   * This function is used to handle notification popup scroll events and load more notifications
   */
  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (((scrollHeight - scrollTop).toFixed() == clientHeight) && !loading && hasMore) {
      fetchNotificationList();
    }
  };

  /**
   * This function is used to handle notification content click and redirect to edit product page
   */
  const handleNotificationClick = (notification: Notification) => {
    navigate(`/edit-product/${notification.link}`);
  };

  const handleToggleButtonChange = (checked: boolean) => {
    setIsPrjCodeWiseChecked(checked);
    setUserAssignmentChartRequest({
      ...userAssignmentChartRequest,
      isPrjCodeWise: checked
    })
  };

  return (
    <>
      <Box>
        <Paper elevation={3} className="user-dashboard-main-container">
          <Grid container spacing={0} className="user-dashboard-grid-container">
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                padding: 2,
                borderRight: { xs: "none", md: "1px solid rgba(0, 0, 0, 0.12)" },
                display: "flex",
                flexDirection: "column",
                minHeight: "620px",
                overflow: "hidden", // Ensure no overflow outside the grid
              }}
            >
              <Typography variant="h5" gutterBottom>
                Recently Accessed Assigned Projects
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "auto", // Add scrolling if content overflows
                  minHeight: "550px"
                }}
                className={projectList.length === 0 ? 'no-scroll' : ''}
              >
                <DataGrid
                  rows={projectList}
                  columns={ProjectListGridColumns}
                  disableColumnMenu
                  sortingOrder={['desc', 'asc']}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 8
                      },
                    },
                  }}
                  hideFooterPagination
                  rowCount={projectList.length}
                  sortingMode="client"
                  getRowHeight={(params) => {
                    const baseHeight = 55;
                    const field = 'col2'; // Example field for dynamic content
                    const content = projectList?.find((row) => row.id === params.id)?.[field] || '';

                    const dynamicHeight = content.split('\n').length * 20; // Example height calculation
                    return Math.max(baseHeight, dynamicHeight);
                  }}
                  rowSelection={false}
                  slots={{
                    footer: renderFooter, // Use the `slots` API for custom footer
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ height: "620px" }}>
              <NotificationContent
                notifications={notifications}
                loading={loading}
                hasMore={hasMore}
                onScroll={handleScroll}
                onNotificationClick={handleNotificationClick}
                maxHeight={600}
              />
            </Grid>
            <Grid item xs={12} className="project-overview-divider" style={{ margin: "20px 0" }}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} className="user-assignment-chart-grid-item">
              <Box display="flex" justifyContent="end" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPrjCodeWiseChecked}
                      onChange={(e) => handleToggleButtonChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={isPrjCodeWiseChecked ? 'Total Project Codes' : 'Total Base Codes'}
                />
              </Box>
              {stageStatusesWithCount?.length > 0 &&
                <StageStatusesChart
                  chartData={stageStatusesWithCount}
                  chartTitle="Stage and Status Wise Active Assignment Projects"
                />}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  )
};

export default UserDashboard;